import XMLParser from "./XMLParser";
import { commentAdded } from "../api/RecentCommentsAPI";

// insert comment into the word doc with a link added to comment as well
export const insertComment = (comment) => {
    Office.context.document.getSelectedDataAsync(
        Office.CoercionType.Ooxml,
        function (result) {
            if (result.status == "succeeded") {
                // Get the OOXML returned from the getSelectedDataAsync call.
                var xml = result.value;

                var date = getCurrentDateFormatted(new Date());
                let xmlParser = new XMLParser(xml, comment.title, comment.link, date);
                xml = xmlParser.newXml();

                Office.context.document.setSelectedDataAsync(xml, { coercionType: Office.CoercionType.Ooxml }, function (asyncResult) {
                    if (asyncResult.status == "failed") {
                        console.debug("Action failed with error: " + asyncResult.error.message);
                    } else {
                        console.debug("Comment added");
                        commentAdded(comment);
                    }
                });
            }
        }
    );
}

// return the current date formated as YYYY-MM-DDTHH:MM:SSZ so we can add it to the 
// ooxml when inserting the comment
function getCurrentDateFormatted(currentDate) {
    // get the current time of the user
    // append 0 to single digit days
    // getMonth starts at 0, cause javascript is stupid
    var month = currentDate.getMonth() + 1;
    if (Number(month) < 10) {
        month = "0" + String(month);
    }
    // append 0 to single digit days
    var dayOfMonth = currentDate.getDate();
    if (Number(dayOfMonth) < 10) {
        dayOfMonth = "0" + String(dayOfMonth);
    }
    var currentDateString = currentDate.getFullYear() + "-" + month + "-" + dayOfMonth
        + "T" + currentDate.getHours() + ":" + currentDate.getMinutes()
        + ":" + currentDate.getSeconds() + "Z";

    return currentDateString;
}