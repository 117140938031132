import React, { useState, useEffect } from 'react';
import { IconButton } from "office-ui-fabric-react";
import {commentAdded, getAllRecentComments, refresh} from "../../../api/RecentCommentsAPI";
import { insertComment } from "../../../comments/CommentInserter";

export default function RecentTab(props) {
    // called when the component loads
    useEffect(() => {
        loadComments();
    }, []);

    const [recentComments, setRecentComments] = useState([]);

    // loads all recent comments
    function loadComments() {
        setRecentComments(getAllRecentComments());
    }

    // clears all recent comments
    function handleClearClick() {
        refresh();
        loadComments();
    }

    // Inserts comment into word doc and adds comment to recents.
    function addCommentToPage(comment) {
        insertComment(comment);
        commentAdded(comment);
        loadComments();  // to render tab correctly
    }

    const recentCommentsContainer = {
        // paddingLeft: "3%",
        // paddingRight: "3%",
        position: "absolute",
        bottom: 0,
        top: 50,
        left: 0,
        right: 0,
    };

    const commentText = {
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "300",
    };

    const commentPath = {
        fontSize: "11px",
        fontStyle: "normal",
        fontWeight: "1200",
    };

    const recentCommentRow = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingLeft: "3%",
        paddingRight: "3%",
    };

    const headerStyle = {
        // margin: 0,
        // display: "inline-block"
        paddingLeft: "3%"
    };

    const buttonStyle = {
        float: "right",
        marginRight: '20px'
    };

    return (
        <div style={recentCommentsContainer}>
            <h1 style={headerStyle}>Recent Comments</h1>
            <IconButton style={buttonStyle} iconProps={{ iconName: "Refresh" }} onClick={() => handleClearClick()} />
            <ul>
                {recentComments.map(function (com, i) {
                    return (
                        <div>
                            <div style={recentCommentRow}>
                                <div>
                                    <h1 style={commentText} onClick={() => addCommentToPage(com)}>{com.title}</h1>
                                    <h1 style={commentPath}>{com.link}</h1>
                                </div>
                            </div>
                            <hr />
                        </div>
                    );
                })}
            </ul>
        </div>
    );
}