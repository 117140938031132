import "office-ui-fabric-react/dist/css/fabric.min.css";
import App from "./App";
import { AppContainer } from "react-hot-loader";
import { initializeIcons } from "office-ui-fabric-react/lib/Icons";
import * as React from "react";
import * as ReactDOM from "react-dom";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { Auth0Provider } from "@auth0/auth0-react";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
/* global AppCpntainer, Component, document, Office, module, React, require */

initializeIcons();

let isOfficeInitialized = false;

const title = "ECoS Add-in";

const render = Component => {
  ReactDOM.render(
    <Auth0Provider
      domain={process.env.AUTH0_DOMAIN + "app.auth0.com"}
      clientId={process.env.ATUH0_CLIENTID}
      redirectUri={window.location.origin}
    >
      <AppContainer>
        <Component title={title} isOfficeInitialized={isOfficeInitialized} />
      </AppContainer>
  </Auth0Provider>,
    document.getElementById("container")
  );
};

/* Render application after Office initializes */
Office.initialize = () => {
  isOfficeInitialized = true;
  render(App);
};

/* Initial render showing a progress bar */
render(App);

if (module.hot) {
  module.hot.accept("./App", () => {
    const NextApp = require("./App").default;
    render(NextApp);
  });
}
