import React, { useState, useEffect } from 'react';
import LibrariesTab from "./tabs/LibrariesTab";
import FavoritesTab from "./tabs/FavoritesTab";
import RecentTab from "./tabs/RecentTab";
import { Tabs, Tab, AppBar, makeStyles, Typography } from "@material-ui/core";
import { Icon } from "office-ui-fabric-react";
import Box from '@material-ui/core/Box';
import ProfileTab from './tabs/ProfileTab';
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";


/*
Description: Dash for the three different tabs (Libraries, Favorites, Recent). Uses materialUI
			 TabPanel component to display three tabs.

Data from props:
	value: ?
	index: represents tab number. 0 is LibrariesTab, 1 is FavoritesTab, 2 is RecentTab, 3 is ProfileTab
	className: defines styling for components
	children:
		props: jwt if index = 0.
		type: function representing tab for index's props.

*/

// creates scrollable tab panel
function TabPanel(props) {
	//
	const { children, value, index, ...other } = props;

	console.log(props);

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-force-tabpanel-${index}`}
			aria-labelledby={`scrollable-force-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography component={'span'}>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

// TabPanel.propTypes = {
// 	children: PropTypes.node,
// 	index: PropTypes.any.isRequired,
// 	value: PropTypes.any.isRequired,
// };

function a11yProps(index) {
	return {
		id: `scrollable-force-tab-${index}`,
		'aria-controls': `scrollable-force-tabpanel-${index}`,
	};
}

// creates style class
const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		width: '100%',
		//backgroundColor: theme.palette.background.paper,
	},
	tabRoot: {
		minWidth: "25%",
	},
	paper: {
		padding: theme.spacing(1),
	},
	tabPanel: {
		marginRight: '2%',
		paddingLeft:'1%'
	}
}));

export default function Dash(props) {
	const classes = useStyles();
	const [value, setValue] = useState(props.menuIndex);
	const [jwt, setJwt] = useState("");
	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		if (jwt === null) {
			history.push({
				pathname: '/login',
				error: "jwt is null",
			});
		}
		console.log(location); // result: '/secondpage'
		setJwt(location.jwt);
	}, [location]);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<div className={classes.root}>
			<AppBar position="static" color="default">
				<Tabs
					value={value}
					onChange={handleChange}
					variant="scrollable"
					scrollButtons="on"
					indicatorColor="primary"
					textColor="primary"
					aria-label="tabs"
				>
					<Tab icon={<Icon iconName="Library" />} classes={{ root: classes.tabRoot }} {...a11yProps(0)} />
					<Tab icon={<Icon iconName="FavoriteStar" />} classes={{ root: classes.tabRoot }} {...a11yProps(1)} />
					<Tab icon={<Icon iconName="Recent" />} classes={{ root: classes.tabRoot }} {...a11yProps(2)} />
					<Tab icon={<Icon iconName="Contact" />} classes={{ root: classes.tabRoot }} {...a11yProps(3)} />
				</Tabs>
			</AppBar>
			<TabPanel value={value} index={0} className={classes.tabPanel}>
				<LibrariesTab jwt={jwt} />
			</TabPanel>
			<TabPanel value={value} index={1} className={classes.tabPanel}>
				<FavoritesTab />
			</TabPanel>
			<TabPanel value={value} index={2} className={classes.tabPanel}>
				<RecentTab />
			</TabPanel>
			<TabPanel value={value} index={3} className={classes.tabPanel}>
				<ProfileTab jwt={jwt}/>
			</TabPanel>
		</div>
	);
}

