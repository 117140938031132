import React, { useState, useEffect } from 'react';
import { IconButton } from "office-ui-fabric-react";
import { getFavoriteComments, unfavoriteComment } from "../../../api/FavoriteCommentAPI";
import { insertComment } from "../../../comments/CommentInserter";

export default function FavoritesTab(props) {
    // list of current favorite comments
    const [favoriteComments, setFavoriteComments] = useState([]);

    // called when the component loads
    useEffect(() => {
        loadComments();
    }, []);

    // loads favorite comments and sets state
    function loadComments() {
        var favComs = getFavoriteComments();
        setFavoriteComments(favComs);
    }

    // unfavorites a comment and updates state
    function handleUnfavoriteClick(comment) {
        unfavoriteComment(comment);
        loadComments();
    }

    const favoriteCommentsContainer = {
        position: "absolute",
        bottom: 0,
        top: 50,
        left: 0,
        right: 0,
    };

    const headerStyle = {
        paddingLeft: "3%",
    };

    const commentText = {
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "300",
    };

    const commentPath = {
        fontSize: "11px",
        fontStyle: "normal",
        fontWeight: "1200",
    };

    const favoriteCommentRow = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingLeft: "3%",
        paddingRight: "3%",
    };

    return (
        <div style={favoriteCommentsContainer}>
            <h1 style={headerStyle}>Favorite Comments</h1>
            <ul>
                {favoriteComments.map(function (com, i) {
                    return (
                        <div>
                            <div style={favoriteCommentRow}>
                                <div>
                                    <h1 style={commentText} onClick={() => insertComment(com)}>{com.title}</h1>
                                    <h1 style={commentPath}>{com.link}</h1>
                                </div>
                                <div>
                                    <IconButton iconProps={{ iconName: "FavoriteStarFill" }}
                                                onClick={() => handleUnfavoriteClick(com)} />
                                </div>
                            </div>
                            <hr />
                        </div>
                    );
                })}
            </ul>
        </div>
    );
}