import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from "@material-ui/core/styles";
import { insertComment } from "../../comments/CommentInserter";

/*
Description: Displays the search bar in the LibrariesTab when user is in a library or section

Data from props:
    libraryId: current library ID
    sections: array of sections in the library
*/

export default function SearchBar(props) {
    // classes is used to set styles using
    // className for each part of the component
    const classes = useStyles();

    // array of comments in library to appear in search bar drop-down
    const [comArr, setComArr] = useState([]);
    useEffect(() => {
        extractComments(props.sections);
    }, []);

    // takes in an array of section objects and returns an array of comment objects
    // comment objects are comments in the given sections
    function extractComments(sections) {
        var allComments = [];
        sections.forEach(section => {
            allComments.push(extractCommentsHelper(section, []));
        });
        setComArr([].concat(...allComments));
        return [].concat(...allComments);
    }

    // takes in a section object and returns an array of all the comments in the section
    function extractCommentsHelper(section, comArr) {
        if (section.comments.length != 0) {
            section.comments.forEach(com => {
                com.section = section.id;
                comArr.push(com);
            });
        } else {
            section.sections.forEach(subSection => {
                extractCommentsHelper(subSection, comArr);
            })
        }
        return comArr;
    }

    // handles clicking on a comment in the search results
    function handleCommentClick(commentText) {
        comArr.forEach(comment => {
            
            if (comment.title === commentText) {
                const endpoint = process.env.WEBSITE_URL;
                comment.link = endpoint + "link/comment/" + props.libraryId + "/"  + comment.section + "/" + comment.id;
                insertComment(comment);
                return comment;
            }
        });
    }

    return (
        <div style={{paddingLeft: "3%", paddingBottom: "3%"}}>
        <Autocomplete
          id="search"
          clearOnEscape
          classes={classes}
          style={{height:60, width: 270}}
          options={comArr.map((comment) => comment.title)}
          onChange={(event, value) => handleCommentClick(value)}
          renderInput={(params) => (
            <TextField {...params} placeholder="Search" label="" margin="normal" variant="outlined" />
          )}
        />
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    inputRoot: {
        color: "black",
        height: 50,
        // padding: 5,
        width: 300,
        // "& .MuiOutlinedInput-notchedOutline": {
        // borderColor: "grey"
        // },
        // "&:hover .MuiOutlinedInput-notchedOutline": {
        // borderColor: "darkGrey"
        // },
        // "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        // borderColor: "darkGrey"
        // },
    },
    // popper: {
    //     height: 80,
    // },
    // paper: {
    //     height: 80,
    // },
    // listbox: {
    //     height: 30,
    // }
}));