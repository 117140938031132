// gets all of a users libraries
// returns libraries as json, null if failed
export function getAllLibraries(jwt) {
    // get all the libs for the given user
    console.log("jwt" + jwt);
    // return fetch(process.env.BACKEND_URL + "/library/getAll", {
    return fetch("https://backend.ecostools.com" + "/library/getAll", {
        method: "GET",
        headers: {
            "Authorization": jwt,
            "Content-Type": "application/json",
        },
    }).then(
        function (response) {
            if (response.status === 200) {
                console.log(response);
                return response.json().then(json => {
                    return json;
                })
            } else {
                return response.text().then(text => {
                    return null;
                })
            }
        }
    )
}


export function silentLogin() {
    var auth0AuthorizeEndPoint = 'https://ecos-app.auth0.com/authorize?'
    var clientID = '&client_id=' + localStorage.getItem('Auth0ClientID')
    var audienceIdentifier = '&audience=https://ecos-app.auth0.com/api/v2/'
    var redirectURL = '&redirect_uri=https://localhost:3000/src/taskpane/popups/popupRedirect.html'
    var settings = 'response_type=token' + clientID + redirectURL + '&scope=openid%20profile%20email&nonce=NONCE' + audienceIdentifier + '&prompt=none'
    console.log(auth0AuthorizeEndPoint + settings)
    return fetch(auth0AuthorizeEndPoint + settings, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        mode: 'no-cors',
    }).then(
        function (response) {
            if (response.status === 200) {
                console.log("silent login ")
                console.log(response);
                // return response.json().then(json => {
                //     return true;
                // })
                return false;
            } else {
                console.log("failed silent login")
                console.log(response)
                // return response.text().then(text => {
                //     return false;
                // })
                return true;
            }
        }
    )
}