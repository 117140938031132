import * as React from "react";

export default function PopupRedirect() {
    // Office.initialize must be called on every page where Office JavaScript is 
    // called. Other initialization code should go inside it.
    React.useEffect(() => {
        Office.initialize = function () {
            $(document).ready(function () {
                try {
                    // Auth0 adds its access token as a hash (#) value on the URL
                    console.log(location);
                    var accessTokenForAuth0 = getCodeFromResUrl('code');
                    console.log(accessTokenForAuth0);

                    // Create the outcome message and send it to the task pane.
                    var messageObject = { outcome: "success", auth0Token: accessTokenForAuth0 };
                    var jsonMessage = JSON.stringify(messageObject);

                    // Tell the task pane about the outcome.
                    Office.context.ui.messageParent(jsonMessage);
                } catch (err) {

                    // Create the outcome message and send it to the task pane.
                    var messageObject = { outcome: "failure", error: err.message };
                    var jsonMessage = JSON.stringify(messageObject);

                    // Tell the task pane about the outcome.
                    Office.context.ui.messageParent("message to parent " + jsonMessage);
                }
            });
        }
    }, []);

    // Function to retrieve a hash string value when the hash
    // value is structured like query parameters.
    function getCodeFromResUrl() {
        var resUrl = location.href;
        return resUrl.substring(resUrl.indexOf('=') + 1);
    }

    return (
        <div>
            <title />
            <meta charSet="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            {/* For the Office UI Fabric, go to http://aka.ms/office-ui-fabric to learn more. */}
            <link rel="stylesheet" href="https://appsforoffice.microsoft.com/fabric/2.1.0/fabric.min.css" />
            <link rel="stylesheet" href="https://appsforoffice.microsoft.com/fabric/2.1.0/fabric.components.min.css" />
            <link rel="stylesheet" href="./index.css" />
            {/* Load the Office JavaScript libraries */}
            <div className="ms-typeramp-dialog">
                <p className="ms-font-m">Please wait for this window to close, before continuing with the add-in.</p>
            </div>
        </div>
    );
}