// post: adds comment to recentComments in localStorage
function commentAdded(comment) {

    // retrieve sorted recent comments
    let recentComments = JSON.parse(localStorage.getItem("recentComments"));

    if (recentComments === null) {
        recentComments = [comment];
    } else {
        // check if comment already in recentComments
        var commentIndex = recentComments.findIndex(e => e.title === comment.title);
        if (commentIndex >= 0) { 
            // if in recentComments, remove it
            recentComments.splice(commentIndex, 1);
        } else {
            // check if recentComments length is at max; 10 is max length
            if (recentComments.length >= 10) {
                recentComments.pop();
            } 
        }
        // add comment to front of recentComments
        recentComments.unshift(comment);
    }
    // update localStorage
    localStorage.setItem("recentComments", JSON.stringify(recentComments));
    return true;
}

// post: returns sorted list of recent comments from newest to oldest
function getAllRecentComments() {

    var recentComments = JSON.parse(localStorage.getItem("recentComments"));
    if (recentComments === null) {
        return [];
    }
    return recentComments;
}

// post: clears recentComments value in localStorage 
function refresh() {
    // clear localStorage
    localStorage.setItem("recentComments", JSON.stringify([]));
    return true;
}

module.exports = {commentAdded, getAllRecentComments, refresh};