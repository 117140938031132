import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { makeStyles } from '@material-ui/core/styles';
import jwt_decode from "jwt-decode";
import Button from '@material-ui/core/Button';

/*
Description: The tab that displays the profile of the current user signed in

Data from props: auth token

States: 

Function from props: 

*/

export default function ProfileTab(props) {
    const [user, setUser] = useState(null);
    const history = useHistory();
    const { logout } = useAuth0();
    const classes = useStyles();
    const [showLoggingOutText, setShowLoggingOutText] = useState(false);

    useEffect(() => {
        var decoded = jwt_decode(props.jwt);
        console.log(JSON.stringify(decoded));
        setUser(decoded);
    }, []);

    function logoutAuth0() {
        // client_id=${encodeURIComponent(process.env.AUTH0_CLIENTID)}&
        setShowLoggingOutText(true);
        // to test logout locally using localhost instead of production, uncomment the other returnUrl below
        
        const returnToURL =  process.env.LOGOUTURL; //"https://localhost:3000/taskpane.html/login";
        const logoutEndpoint = `https://care37-cors-anywhere.herokuapp.com/https://ecos-app.auth0.com/v2/logout?
        federated&
        client_id=rgF63hMW39iwc6DKmdCYtB9bjrh7eCZu&
        returnTo=${encodeURIComponent(returnToURL)}`
        fetch(logoutEndpoint, {
            method: "GET",
            headers: {
            },
        }).then(res => {
            console.log("results " + res)
            history.push("/login");
            setShowLoggingOutText(false);
        }).catch(err => {
            console.log("error " + err)
        });
    }

    return (
        <div>
            {user !== null && (
                <div>
                    <p class="ms-navigation__content ms-font-xl">{user.name}</p>
                    <p class="ms-navigation__content ms-font-xl">{user.email}</p>
                    {showLoggingOutText ? (
                        <p class="ms-navigation__content ms-font-xl">Logging you out...</p>
                    ) : (
                        <Button onClick={() => logoutAuth0()} className={classes.button}>Logout</Button>
                    )}
                </div>
            )}
        </div>
    );
}

const useStyles = makeStyles({
    button: {
        background: '#C4C4C4',
        borderRadius: '10px',
        marginBottom: '10px',
        "&:hover": {
            backgroundColor: '#C4C4C4'
        },
        minWidth: '200px',
    },
});