export default class XMLParser {
    constructor(xml,comment,link,date) {
      this.xml = xml;
      this.comment = comment;
      this.link = link;
      this.date = date;
      this.noLink = (this.link === null || typeof this.link === 'undefined' || this.link.length === 0);
    }

    newXml() {
        this.addCommentRelationship();

        this.addCommentPart();

        var commentId = this.getCommentId();

        this.addCommentToPart(commentId);

        this.addCommentToDoc(commentId);

        return this.xml;
    }


    // add comment relationship to document.xml.rels
    addCommentRelationship() {
        // find document relationship part
        var start = this.xml.indexOf('document.xml.rels');
        var end = this.xml.indexOf('</pkg:part',start);
        var docRel = this.xml.substring(start,end);
        
        // Find comment relationship and the max id
        var relId = 0, i = 0;
        var isComment = (docRel.indexOf('comments.xml') > 0);
        while (!isComment) {
            var next = docRel.indexOf('<Relationship ', i);
            if (next < 0)
                break;
            i = next+1;
            var id = this.findId(docRel, 'rId', 'Type', i, 3);
            relId = Math.max(id, relId);
        }
        
        // no comment relationship
        if (!isComment) {
            // add comment relationship to relationships
            var pos = this.xml.lastIndexOf('</Relationships>', end);
            relId++;
            var body = '<Relationship Id="rId'+relId+'" Type="http://schemas.openxmlformats.org/officeDocument/2006/relationships/comments" Target="comments.xml" />';
            this.insertAfter(body,pos);
        }
    }

    // add comments.xml if there is none
    addCommentPart() {
        // find comments.xml
        if (this.xml.indexOf('pkg:name="/word/comments.xml"') < 0) {
            var commentXml = ' <pkg:part pkg:name="/word/comments.xml" pkg:contentType="application/vnd.openxmlformats-officedocument.wordprocessingml.comments+xml"><pkg:xmlData>'
                + '<w:comments xmlns:w="http://schemas.openxmlformats.org/wordprocessingml/2006/main" xmlns:r="http://schemas.openxmlformats.org/officeDocument/2006/relationships">'
                + '</w:comments></pkg:xmlData></pkg:part>';

            // add after document.xml
            var start = this.xml.indexOf('pkg:name="/word/document.xml"');
            var end = this.xml.indexOf('</pkg:part>', start)+11;
            this.insertAfter(commentXml,end);
        }
    }

    // find max comment id
    getCommentId() {
        var start = this.xml.indexOf('pkg:name="/word/comments.xml"');
        var end = this.xml.indexOf('</pkg:part>', start);
        var commentXml = this.xml.substring(start,end);

        // find max id
        var commentId = -1, i = 0;
        while (true) {
            var next = commentXml.indexOf('<w:comment ', i);
            if (next < 0)
                break;
            i = next+1;
            var id = this.findId(commentXml, 'w:id', 'w:author', i, 6);
            commentId = Math.max(id, commentId);
        }

        return commentId+1;
    }

    // add comment to comments.xml
    addCommentToPart(commentId) {
        var start = this.xml.indexOf('pkg:name="/word/comments.xml"');
        var end = this.xml.indexOf('</pkg:part>', start);

        var pos = this.xml.lastIndexOf('</w:comments', end);
        var comment = '<w:comment w:id="'+commentId+'" w:author="ECoS" w:date="'+this.date+'" w:initials="JH">'
                +'<w:p><w:r><w:t>'+this.comment+'</w:t></w:r>';
        if (!this.noLink) {
            this.addLinkRelationship();
            var linkId = this.getLinkId();
            this.addLinkToRelationship(linkId);
            comment += this.getLink(linkId);
        }
        comment +='</w:p></w:comment>';
        this.insertAfter(comment,pos);
    }

    // add comment to document.xml
    addCommentToDoc(commentId) {
        // get the first <w:p> node
        var start = this.xml.indexOf('pkg:name="/word/document.xml"');
        var end = this.xml.indexOf('</pkg:part',start);
        var firstPNode = this.xml.indexOf('<w:p ', start);
        
        if (this.isDocEmpty(start,end,firstPNode)) {
            // there is no text
            var close = this.xml.indexOf('/>',firstPNode);
            var pre = this.xml.substring(0,close);
            var post = this.xml.substring(close+2);
            this.xml = pre + '></w:p>' + post;
        }

        var commentStart = '<w:commentRangeStart w:id="'+commentId+'" />';
        var commentEnd = '<w:commentRangeEnd w:id="'+commentId+'" /><w:r><w:commentReference w:id="'+commentId+'" /></w:r>';

        // insert w:commentRangeStart into the first <w:p>
        this.insertAfter(commentStart,this.xml.indexOf('>',firstPNode)+1);

        // append w:commentRangeEnd to the last non-empty <w:p>
        this.insertAfter(commentEnd,this.xml.lastIndexOf('</w:p>', end));
    }


    // add or find comments.xml.rels
    addLinkRelationship() {
        // find comments.xml.rels
        if (this.xml.indexOf('pkg:name="/word/_rels/comments.xml.rels"') < 0) {
            var commentRel = ' <pkg:part pkg:name="/word/_rels/comments.xml.rels" pkg:contentType="application/vnd.openxmlformats-package.relationships+xml"><pkg:xmlData>'
                + '<Relationships xmlns="http://schemas.openxmlformats.org/package/2006/relationships"></Relationships></pkg:xmlData></pkg:part>';

            // add after comments.xml
            var start = this.xml.indexOf('pkg:name="/word/comments.xml"');
            var end = this.xml.indexOf('</pkg:part>', start)+11;
            this.insertAfter(commentRel,end);
        }
    }

    // find max link id
    getLinkId() {
        var start = this.xml.indexOf('pkg:name="/word/_rels/comments.xml.rels"');
        var end = this.xml.indexOf('</pkg:part>', start);
        var linkRel = this.xml.substring(start,end);

        var linkId = 0, i = 0;
        while (true) {
            var next = linkRel.indexOf('<Relationship ', i);
            if (next < 0)
                break;
            i = next+1;
            var id = this.findId(linkRel, 'rId', 'Type', i, 3);
            linkId = Math.max(id, linkId);
        }

        return linkId+1;
    }

    // add link relationship to comments.xml.rel
    addLinkToRelationship(linkId) {
        var start = this.xml.indexOf('pkg:name="/word/_rels/comments.xml.rels"');
        var end = this.xml.indexOf('</pkg:part>', start);

        var pos = this.xml.lastIndexOf('</Relationships', end);
        var linkRel = '<Relationship Id="rId'+linkId+'" Type="http://schemas.openxmlformats.org/officeDocument/2006/relationships/hyperlink" Target="'+this.link+'" TargetMode="External" />';
        this.insertAfter(linkRel,pos);
    }

    // return <w:hyperlink .../>
    getLink(linkId) {
        return '<w:hyperlink r:id="rId'+linkId+'"><w:r><w:rPr><w:rStyle w:val="Hyperlink" /><w:color w:val="0070C0" /><w:u w:val="single" />'
            + '</w:rPr><w:t><w:br /><w:br />Link (ctrl/cmd + click, to open)</w:t></w:r></w:hyperlink>'
    }

    findId(string, startTag, endTag, i, numPos) {
        var idStart = string.indexOf(startTag,i);
        var idEnd = string.indexOf(endTag,i);
        var idString = string.substring(idStart,idEnd).trim();

        return Number(idString.substring(numPos,idString.length-1));
    }

    insertAfter(string, pos) {
        var pre = this.xml.substring(0,pos);
        var post = this.xml.substring(pos);
        this.xml = pre + string + post;
    }

    isDocEmpty(start,end,firstPNode) {
        return this.xml.lastIndexOf('<w:p ',end) === firstPNode
         && this.xml.lastIndexOf('</w:p>',end) < start;
    }
}

