import React, { useState, useEffect } from 'react';
import LibrariesView from '../views/LibrariesView';
import SectionsView from '../views/SectionsView';
import CommentsView from '../views/CommentsView';
import Path from "../Path";
import { getAllLibraries } from "../LibraryFunctions";
import SearchBar from "../SearchBar";

/*
Description: The tab that displays the libraries of the user

Data from props:
  jwt: auth0 token

Function from props: 

*/

export default function LibrariesTab(props) {
  // current library id
  const [currentLibraryId, setCurrentLibraryId] = useState(-1);
  // current library data
  const [librariesData, setLibrariesData] = useState([]);
  // array of strings of the names of the libraries and sections
  const [path, setPath] = useState([]);
  // array of the selected libraries/sections/subsections objects
  const [selectedStack, setSelectedStack] = useState([]);

  // fetch dummy data
  useEffect(() => {
    // load libraries
    // setLibrariesData(getTestLibraryData());
    if (props.jwt !== "") {
      updateLibrariesData();
    }
  }, [props.jwt]);


  // updates the library data after we make a call.
  // this is a cop out, later we should modify the api response
  // to return the updated data so we do not have to make 
  // the extra api call
  function updateLibrariesData() {
    getAllLibraries(props.jwt).then((libraries) => {
      if (libraries !== null) {
        setLibrariesData(libraries);
      } else {
        console.log("error retrieving the users libraries");
      }
    });
  }

  // handles path back button click
  function handleBackButton() {
    var newPathArr = path.slice(0, -1);
    setPath(newPathArr);
    var newArr = selectedStack.slice(0, -1);
    setSelectedStack(newArr);
  }

  // handles clicking one of the library/section names in the path
  function handlePathClick(index) {
    var newPathArr = path.slice(0, index + 1);
    setPath(newPathArr);
    var newArr = selectedStack.slice(0, index + 1);
    setSelectedStack(newArr);
  }

  // handles library or section card click
  function handleClick(value) {
    console.log("Current path");
    console.log(path);
    setPath(path => path.concat(value.name));
    if (selectedStack.length == 0) {
      setSelectedStack(selectedStack => selectedStack.concat(value.libraryContent));
      setCurrentLibraryId(value.id);
    } else {
      setSelectedStack(selectedStack => selectedStack.concat(value));
    }
  }

  // checks if section has comments
  function hasComments(section) {
    if (section.comments != null && section.comments.length != 0) {
      return true;
    }
    return false;
  }

  return (
    <div style={libraryTabContainer}>
      {selectedStack.length === 0 ? <h1 style={{marginLeft: '2%'}}>My Libraries</h1> : null}
      {selectedStack.length > 0 && !(hasComments(selectedStack[selectedStack.length - 1])) ?
        <SearchBar sections={selectedStack[0].sections} libraryId={currentLibraryId}/> : null}
      {path.length != 0 ? <Path handlePathClick={handlePathClick} handleBackButton={handleBackButton} path={path} /> : null}
      {selectedStack.length === 0 ?
        <LibrariesView handleClick={handleClick} libraries={librariesData} /> : null}
      {selectedStack.length > 0 && !(hasComments(selectedStack[selectedStack.length - 1])) ?
        <SectionsView handleClick={handleClick}
          name={path[path.length - 1]}
          library={selectedStack[selectedStack.length - 1]} /> : null}
      {selectedStack.length > 0 && hasComments(selectedStack[selectedStack.length - 1]) ?
        <CommentsView section={selectedStack[selectedStack.length - 1]}
          libraryId={currentLibraryId} /> : null}

    </div>
  );
}

const libraryTabContainer = {
  position: "absolute",
  bottom: 0,
  top: 50,
  left: 0,
  right: 0,
};