import * as React from "react";
import Progress from "./old-components/Progress";
import Login from "./components/Login";
import Dash from "./components/Dash";
import Popup from "./components/popups/convertedPopup";
import PopupRedirect from "./components/popups/convertedPopupRedirect";

// React Router
import {
  HashRouter as Router,
  Switch,
  Route,
  useHistory,
  Redirect
} from "react-router-dom";

export default class App extends React.Component {
  constructor(props, context) {
    super(props, context);
  }
 
  render() {
    const { title, isOfficeInitialized } = this.props;

    if (!isOfficeInitialized) {
      return (
        <Progress title={title} 
          logo="assets/logo-filled.png" 
          message="Please sideload your addin to see app body." />
      );
    }
    
    return (
      <div className="ms-welcome" style={{backgroundColor:'transparent'}}>
        { this.props.location && console.log("query params: " + JSON.stringify(this.props.location.search))}
        <Router>
          <div >
            <Redirect exact from="/" to="/login" />
            <Switch>
              <Route exact path="/login">
                <Login/>
              </Route>
              <Route exact path="/home">
                <Dash menuIndex={0} />
              </Route>
              <Route exact path="/popup">
                <Popup />
              </Route>
              <Route exact path="/popupRedirect">
                <PopupRedirect />
              </Route>
            </Switch>
          </div>
        </Router>
      </div>
    )
  }
}