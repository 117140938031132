import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import ListSubheader from "@material-ui/core/ListSubheader";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import red from '@material-ui/core/colors/red';

/*
Description: Displays the users libraries

Data from props:
- Array of library objects (libraries), library object
will have a name, description, type (comment/text) (can ignore for now)

Function from props:
- handleClick, defines behavior when library is clicked by user
*/

export default function LibrariesView(props) {
    const classes = useStyles();

    function handleLibrary(library) {
        console.log(library);
        props.handleClick(library);
    }

    return (
        <MuiThemeProvider theme={CustomTheme}>
            {props.libraries.length === 0 && (
                <p className="ms-navigation__content ms-font-xl">Create a library at <a href="https://ecostools.com">ecostools.com</a></p>
            )}
            {props.libraries.map(library => (
                <Card onClick={() => handleLibrary(library)} className={classes.card}>
                    <CardActionArea centerRipple={false} className={classes.alignItemsAndJustifyContent}>
                        <CardContent container >
                            <GridListTileBar
                                title={library.name}
                                subtitle={library.description}
                                className={classes.tileBar} />
                        </CardContent>
                    </CardActionArea>
                </Card>))}
        </MuiThemeProvider>
    );
}

const useStyles = makeStyles(theme => ({
    card: {
        height: 100,
        marginBottom: 10,
        backgroundColor: 'whitesmoke'
    },
    alignItemsAndJustifyContent: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const CustomTheme = createMuiTheme({
    overrides: {
        MuiGridListTileBar: {
            root: {
                backgroundColor: 'whitesmoke'
            },
            title: {
                color: 'black',
            },
            subtitle: {
                color: 'gray',
            }
        },
    }
});