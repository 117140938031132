import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import { isWhiteSpaceLike } from 'typescript';
import ButtonBase from "@material-ui/core/ButtonBase";
import Grid from '@material-ui/core/Grid';

/*
Description: Displays all the sections of a library or sub sections of a section

Data from props:
    name - section name
    library object (library) - this will be a json
object that has all the data of the library

States: current search text in search bar, current sections to display

Function from props:
    handleClick, defines behavior on user click of section object
*/

export default function SectionsView(props) {
    const classes = useStyles();

    function handleSection(section) {
        props.handleClick(section);
    }

    return (
        <div>
            <Typography className={classes.title}>{props.name}</Typography>
            <GridList cols={2} cellHeight='auto'>
                {props.library.sections.map(section => (
                    <GridListTile cols={1}>
                        <Card onClick={() => handleSection(section)} className={classes.card}>
                            <CardActionArea centerRipple={false} className={classes.alignItemsAndJustifyContent}>
                                <CardContent>
                                    <Typography>{section.name}</Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </GridListTile>
                ))}
            </GridList>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    card: {
        height: 100,
        marginBottom: 10,
        marginRight: 5,
        marginLeft: 5,
        backgroundColor: 'whitesmoke',
    },
    alignItemsAndJustifyContent: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        marginLeft: 5,
        marginBottom: 10,
        fontWeight: 'bold',
        fontSize: 'larger',
        color: 'gray',
    },
}));