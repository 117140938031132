// Takes in a parameter of "text"
export const insertText = (text) => {
    //Read the data in the current selection in the document
    Office.context.document.getSelectedDataAsync(
        Office.CoercionType.Text, //The type of datastructure to return
        { valueFormat: "unformatted" }, //specifies that the return value is unformatted
        function (result) { 
            if (result.status == "succeeded") { //if the request went through
                var value = result.value; //take the existing value of the result

                // Create newText by appending text to xml.
                var myText = text;
                var newText = value + " " + myText;

                // Replace selected text with newText value.
                Office.context.document.setSelectedDataAsync(newText, { coercionType: "html" }, function (asyncResult) {
                    //if failed print to console    
                    var error = asyncResult.error;
                    if (asyncResult.status == "failed") {
                        console.debug("Action failed with error: " + error.message);
                    }
                });
            }
        }
    );
} 