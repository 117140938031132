import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBack from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';


/*
Description: Displays the path in the LibrariesTab

Data from props:
    path: array of current navigation path, such as [library, section, subsection, ...]

Function from props: 
    handleBackButton: function that defines app behavior when user clicks on back button in the path bar
    handlePathClick: function that defines behavior when user clicks on library/section names in the path bar
*/

export default function Path(props) {
    // classes is used to set styles using
    // className for each part of the component
    const classes = useStyles();

    // used when the back button is pressed
    function handleBackClick() {
        props.handleBackButton();
    }

    // used when library/section names in path are clicked
    function handlePathClick(index) {
        props.handlePathClick(index);
    }

    return (
        <div className={classes.root}>
            <div className={classes.tabs}>
              
                    <IconButton aria-label="delete" size="small" onClick={() => handleBackClick()}>
                        <ArrowBack className={classes.backButton} />
                    </IconButton>
                
                    {props.path.map(pathItem => (
                        <div 
                        className={
                            (props.path.indexOf(pathItem) == props.path.length - 1)
                            ? classes.tab 
                            : classes.tab} 
                        key={props.path.indexOf(pathItem)}
                        onClick={() => handlePathClick(props.path.indexOf(pathItem))}
                        >
                            {(props.path.indexOf(pathItem) == props.path.length - 1) 
                            ? pathItem 
                            : (pathItem + " >")}
                        </div>
                    ))}
            </div>
        </div>
    );
}

const commentRow = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: "3%",
    paddingRight: "3%",
};

//style settings used by the componenet
const useStyles = makeStyles((theme) => ({
    tabs: {
        backgroundColor: 'transparent',
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        marginLeft: '2%',
        flexWrap: 'wrap',
        alignItems: "center"
    },
    tab: {
        marginRight: '1.5%',
        fontSize: 'medium',
        color: 'silver'
    },
    lastTab: {
        marginRight: '1.5%',
        fontSize: 'medium',
        color: 'grey'
    },
    root: {
        flexGrow: 1,
        marginTop: '3%'
    },
    backButton: {
        fontSize: "medium",
        fontWeight: 500
    }
}));