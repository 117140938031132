// favorites the comment passed in
// comment: the comment to favorite
// returns: true is successful, false if comment was already favorited 
function favoriteComment(comment) {
    // get the list of favorited comments
    var storedFavComments = JSON.parse(localStorage.getItem("favoriteComments"));
    if (storedFavComments === null) {
        storedFavComments = [];
    }
    // check if comment was already favorited 
    if (storedFavComments.some(e => e.title == comment.title)) {
        return false;
    }
    // add the comment to the list
    storedFavComments.push(comment);
    // put the list back into storage
    localStorage.setItem("favoriteComments", JSON.stringify(storedFavComments));
    return true;
}

// unfavorite the comment passed in
// comment: the comment to unfavorite
// returns true if successful, false if not
function unfavoriteComment(comment) {
    // get the list of favorited comments
    var storedFavComments = JSON.parse(localStorage.getItem("favoriteComments"));
    
    if (storedFavComments === null) {
        return false;
    }
    // remove it from the list, (if it exists)
    var found = false;
    for (var i = 0; i < storedFavComments.length; i++) {
        if (storedFavComments[i].title === comment.title) {
            storedFavComments.splice(i, 1);
            found = true;
            break;
        }
    }
    if (!found) {
        return false;
    }
    // put the list back into storage
    localStorage.setItem("favoriteComments", JSON.stringify(storedFavComments));
    return true;
}

// gets of all the favorited comments
// return: an array of comments objects
function getFavoriteComments() {
    var storedFavComments = JSON.parse(localStorage.getItem("favoriteComments"));
    if (storedFavComments === null) {
        storedFavComments = [];
    }
    return storedFavComments;
}

module.exports = {favoriteComment, unfavoriteComment, getFavoriteComments};