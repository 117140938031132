import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import { makeStyles } from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';
import { useLocation } from "react-router-dom";
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { silentLogin } from "./LibraryFunctions";

function Login() {
    var history = useHistory();
    const location = useLocation();
    var Auth0AccountData = Auth0AccountData || {};
    const [clickedLogin, setClickedLogin] = useState(false);
    const [wasLoginError, setWasLoginError] = useState(false);
    // const [displayLogin, setDisplayLogin] = useState(false);
    useEffect(() => {
        console.log("location in login: " + JSON.stringify(location));
        if (location.error === "jwt is null") {
            setWasLoginError(true);
        } 
        // setDisplayLogin(silentLogin())
    }, [location]);

    // Replace the placeholders in the next two lines.
    Auth0AccountData.subdomain = process.env.AUTH0_DOMAIN; //"ecos-app";
    Auth0AccountData.clientID = process.env.AUTH0_CLIENTID;
    Auth0AccountData.clientSecret = process.env.AUTH0_SECRET;
    // The Auth0 subdomain and client ID need to be shared with the popup dialog
    localStorage.setItem('Auth0Subdomain', Auth0AccountData.subdomain);
    localStorage.setItem('Auth0ClientID', Auth0AccountData.clientID);

    var dialog;

    // This handler responds to the success or failure message that the pop-up dialog receives from the identity provider.
    function processMessage(arg) {
        var messageFromPopupDialog = JSON.parse(arg.message);
        if (messageFromPopupDialog.outcome == "success") {
            // The Auth0 token has been received, so close the dialog, use
            // the token to get user information, and redirect the task
            // pane to the landing page.
            dialog.close();
            var authToken = messageFromPopupDialog.auth0Token;
            runLoginProcess(authToken)

        } else {

            // Something went wrong with authentication or the authorization of the web application,
            // either with Auth0 or with the provider.
            dialog.close();
            app.showNotification("User authentication and application authorization",
                "Unable to successfully authenticate user or authorize application: " + messageFromPopupDialog.error);
        }
    }

    // Use the Office dialog API to open a pop-up and display the sign-in page for choosing an identity provider.
    function showLoginPopup(isFirstLogin) {

        if (clickedLogin) {
            return;
        }
        setClickedLogin(true);

        // Create the popup URL and open it.        
        // var fullUrl = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + "/popup.html";
        // to test localhost run popup.html and not the dployed version uncomment the fullUrl to the localhost url instead
        var fullUrl = "https://addin.ecostools.com/src/taskpane/popups/popup.html"//"https://localhost:3000/src/taskpane/popups/popup.html";
        // console.log("jwt before:")
        // console.log(getJWT(localStorage.getItem('Auth0ClientID')))
        // height and width are percentages of the size of the screen.
        Office.context.ui.displayDialogAsync(fullUrl,
            { height: 45, width: 55 },
            function (result) {
                setClickedLogin(false);
                dialog = result.value;
                dialog.addEventHandler(Microsoft.Office.WebExtension.EventType.DialogMessageReceived, processMessage);
            });
    }

    function runLoginProcess(auth) {
        console.log('auth')
        console.log(auth)
        if (auth.charAt(auth.length - 1) == "#") {
            auth = auth.slice(0, -1)
        }
        getAccessToken(auth).then(jwt =>{
            console.log('jwt')
            console.log(jwt);
            login(jwt);
            history.push({
                pathname: '/home',
                jwt: jwt
            });
        });
    }

    // to test access token retrival locally using the locahost popup and popupRedirect change the redirect uri to the commented one
    async function getAccessToken(authToken) {
        const response = await fetch('https://ecos-app.auth0.com/oauth/token', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                grant_type: 'authorization_code',
                client_id: 'rgF63hMW39iwc6DKmdCYtB9bjrh7eCZu',
                client_secret: 'fUp4fxcZ7GjR73zI_j2YVVcPAWQHJqrNUdXluL4BcRaHz4KwEx2cAfX2FBgmW-JL',
                code: authToken,
                redirect_uri: "https://addin.ecostools.com/src/taskpane/popups/popupRedirect.html"//"https://localhost:3000/src/taskpane/popups/popupRedirect.html" 
            })
        });
        var ret = null;
        if (response.status == 200) {
            ret = await response.json()
        } else if (response.status == 500) {
            ret = await response.json()
            console.log(ret);
            return null;
        } else {
            console.log(response);
            return null;
        }
        var jwtToken = await getIdToken(ret);
        return jwtToken;
    }

    function getIdToken (json) {
        console.log(json);
        var jwtToken = json['id_token'];
        return jwtToken;
    }

    function login(jwtToken) {
        var loginEndpoint = "https://backend.ecostools.com/auth/login";  // "https://backend.ecostools.com//auth/login";
        fetch(loginEndpoint, {
            method: "GET",
            headers: {
                "Authorization": jwtToken,
            },
        }).then(
            function (response) {
                if (response.status == 200) {
                    response.json().then(json => {
                        console.log(json);
                        // create the user if it does not exist
                        if (json.id === -1) {
                            createUser(jwtToken);
                        }
                    })
                } else if (response.status == 500) {
                    // there was an error with the DB
                    response.json().then(json => {
                        console.log(json);
                    })
                } else {
                    // unexpected error
                    console.log(response);
                }
            }
        )

    }

    function createUser(jwtToken) {
        fetch(oauthTokenEndPoint, {
            method: "POST",
            headers: {
                "Authorization": jwtToken,
            },
        }).then(
            function (response) {
                if (response.status == 200) {
                    response.json().then(json => {
                        console.log("created user");
                        // TODO store the id of the user for future calls
                        console.log(json);
                    })
                } else if (response.status == 500) {
                    // there was an error with the DB
                    response.json().then(json => {
                        console.log(json);
                    })
                } else {
                    // unexpected error
                    console.log(response);
                }
            }
        )
    }

    const classes = useStyles();

    return (
        <div>
            {wasLoginError ? (
                <div style={{ marginTop: '5%' }}>
                    <Typography className={classes.text}>
                        {/* Oops, looks like there was an error logging you in. <br />
                        Give it another shot. If the error continues <a href="https://ecostools.com/support">contact us.</a> */}
                        You are ready to go!
                        Click the button below to get started.
                        </Typography>
                    <Button onClick={() => showLoginPopup(false)} className={classes.button}>Get Started</Button>
                    
                </div>
            ) : (
                     <div style={{ marginTop: '5%' }}>
                        <div>
                            <Card style={{ boxShadow: 'none', backgroundColor: 'transparent' }}>
                                <CardMedia
                                    className={classes.media}
                                    //image="./logo-filled.png"
                                    image={require('../../assets/logo-filled.png')}
                                    title="Logo"
                                />
                            </Card>
                        </div>
                        <div className={classes.text}>
                            <Typography className="ms-navigation__content ms-font-m">
                                To start using ECoSPro, press the button below to log in/create an account.
                            </Typography>
                            <Typography className="ms-navigation__content ms-font-m">
                                Creating an account allows you to create libraries of comments on our
                            <Link href="https://ecostools.com"> website</Link> and insert those comments into the
                                word doc using the addin.
                            </Typography>
                        </div>
                            <Button onClick={() => showLoginPopup(true)} className={classes.button} >
                                Log In/Sign Up
                            </Button>
                    </div> 
                )}
        </div>
    );  
}

const useStyles = makeStyles({
    text: {
        margin: 'auto',
        maxWidth: 250,
        display: 'block',
        textAlign: 'center',
        marginTop: '1%'
    },
    buttonRoot: {
        midWidth: 200,
        paddingTop: '20px',
        boxShadow: 'none'
    },
    actionBar: {
        justifyContent: 'center',
    },
    button: {
        background: '#C4C4C4',
        borderRadius: '10px',
        "&:hover": {
            backgroundColor: '#C4C4C4'
        },
        maxWidth: '200px',
        display: 'block',
        margin: 'auto',
    },
    buttonContainer: {
        margin: 'auto',
        
    },
    media: {
        boxShadow: 'none',
        height: '100px',
        width: '100px',
        margin: 'auto'
    },
});

export default Login;