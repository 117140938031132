import * as React from "react";

export default function Popup() {
    // Redirect to Auth0 and tell it which provider to use.
    var auth0AuthorizeEndPoint = 'https://' + 'ecos-app' + '.auth0.com/authorize/';

    function redirectToIdentityProvider(provider) {

        window.location.replace(auth0AuthorizeEndPoint
            + '?'
            + 'response_type=code'
            + '&client_id=' + localStorage.getItem('Auth0ClientID')
            + '&redirect_uri=https://' + process.env.ADDIN_URL + '/popupRedirect'
            + '&scope=openid%20profile%20email'
            + '&connection=' + provider);
    }

    return (
        <div>
            {/* Hello world */}
            <div className="awesome" style={{ border: '1px solid red' }}>
                <label htmlFor="name">Enter your name: </label>
                <input type="text" id="name" />
            </div>
            <p>Enter your HTML here</p>
            <title />
            <meta charSet="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            {/* For the Office UI Fabric, go to http://aka.ms/office-ui-fabric to learn more. */}
            <link rel="stylesheet" href="https://appsforoffice.microsoft.com/fabric/2.1.0/fabric.min.css" />
            <link rel="stylesheet" href="https://appsforoffice.microsoft.com/fabric/2.1.0/fabric.components.min.css" />
            <link rel="stylesheet" href="./index.css" />
            <div className="ms-typeramp-dialog">
                <p className="ms-navigation__content ms-font-xl">LOG IN/SIGN UP OPTIONS</p>
                <div className="ms-navigation__content">
                    <button id="googleButton" onClick={() => redirectToIdentityProvider("google-oauth2")} className="ms-socialSignInButton ms-Button ms-googleColor">
                        <span className="ms-buttonTextColor ms-Button-label">Google</span>
                    </button>
                </div>
                <div className="ms-navigation__content">
                    <button id="msAccountButton" onClick={() => redirectToIdentityProvider("windowslive")} className="ms-socialSignInButton ms-Button ms-microsoftAccountColor">
                        <span className="ms-buttonTextColor">Microsoft</span>
                    </button>
                </div>
            </div>
        </div>
    );
}