import React, { useState, useEffect } from 'react';
import { insertComment } from '../../../comments/CommentInserter';
import {insertText} from '../../../comments/TextInserter';
import { withStyles } from '@material-ui/core/styles';
import Notes from '@material-ui/icons/Notes';
import InsertComment from '@material-ui/icons/InsertComment';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { IconButton } from "office-ui-fabric-react";
import { favoriteComment, unfavoriteComment, getFavoriteComments } from "../../../api/FavoriteCommentAPI";
import { commentAdded } from "../../../api/RecentCommentsAPI";


/*

Description: Displays the comments in a section of the library.

Data from props:
    libraryId: current library ID
    section: current section object, which contains,
        commentOrder
        comments
        id
        name
        sectionOrder
        sections

*/

export default function CommentsView(props) {
    // called when the component loads
    useEffect(() => {
        loadFavoriteComments();
    }, []);
    // Favorite comments
    const [favoriteComments, setFavoriteComments] = useState([]);
    // Hard-coded categories for ECoS tabs
    const categories = ['Efficiency', 'Content', 'Style'];
    // Current ECoS tab
    const [tabsValue, setTabsValue] = useState(0);
    // Keeps track of whether category has a comment
    let containsComment = [false, false, false];

    // increments the existing tab value
    const handleChange = (event, newTabsValue) => {
        setTabsValue(newTabsValue);
    };

    // unfavorites a comment
    function handleFavoriteClick(comment) {
        if (!unfavoriteComment(comment)) {
            favoriteComment(comment);
        }
        loadFavoriteComments();
    }

    console.log("comments props" + JSON.stringify(props.section.comments));

    // loads the favorite comments
    function loadFavoriteComments() {
        var favComs = getFavoriteComments();
        setFavoriteComments(favComs);
    }

    // True if comment is a favorite comment, false otherwise
    function isFavoriteComment(comment) {
        return favoriteComments.some(e => e.title == comment.title);
    }

    // Inserts comment into word doc and adds comment to recents.
    function addCommentToPage(comment) {
        console.log("comment clicked")
        console.log(comment)
        insertComment(comment);
        
        commentAdded(comment);
        console.log("inserted comment");
    }

    function handleCommentInsert(comment) {
        insertText(comment.title)
    }

    //console.log("comments props" + JSON.stringify(props.section.comments));

    return (
        <div>
            <h1 style={title}>{props.section.name}</h1>
            <div>
                <StyledTabs value={tabsValue} onChange={handleChange} aria-label="styled tabs example">
                    {categories.map(category => (
                        <StyledTab label={category} {...a11yProps(categories.indexOf(category))} />
                    ))}
                </StyledTabs>
            </div>
            <Divider />
            {props.section.comments.map(comment => {
                //console.log("the comment is: " + JSON.stringify(comment));
                containsComment[categories.indexOf(comment.category)] = true;
                const endpoint = "https://ecostools.com"; // process.env.WEBSITE_URL;
                comment.link = endpoint + "/link/comment/" + props.libraryId + "/" + props.section.id + "/" + comment.id;
                return (
                    <TabPanel value={tabsValue} index={categories.indexOf(comment.category)}>
                        <div style={commentRow}>
                                <h1 style={commentText}>{comment.title}</h1>
                            <div style={commentCol}>
                                <div>
                                    <IconButton 
                                    title='Favorite Comment'
                                    iconProps={{ iconName: isFavoriteComment(comment) ? "FavoriteStarFill" : "FavoriteStar" }}
                                    onClick={
                                        (event) => {
                                            event.stopPropagation();
                                            handleFavoriteClick(comment);
                                        }
                                    } 
                                    />
                                </div>
                                <div>
                                    <IconButton
                                        title='Insert Comment'
                                        aria-label="insert"
                                        size="medium"
                                        onClick={() => addCommentToPage(comment)}>
                                        <InsertComment />
                                    </IconButton> 
                                </div>
                                <div>
                                    <IconButton
                                        title='Insert Text'
                                        aria-label="insert"
                                        size="medium"
                                        onClick={() => handleCommentInsert(comment)}>
                                        <Notes />
                                    </IconButton> 
                                </div>
                            </div>
                        </div>
                        <Divider />
                    </TabPanel>
                );
            })}
        </div>
    );
}

// Style specifications for StyledTab and StyledTabs
const StyledTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        color: 'black',
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(13),
        marginRight: theme.spacing(1),
        '&:focus': {
            opacity: 1,
        },
        marginLeft: '1%',
        padding: 0
    },
}))((props) => <Tab disableRipple {...props} />);

const StyledTabs = withStyles({
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        '& > span': {
            maxWidth: 40,
            width: '100%',
            backgroundColor: '#635ee7',
        },
    },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

// Sets style for the title and the comments display
const title = {
    "color": "grey",
    "fontSize": 'large',
    "marginLeft": '2%',
    "marginTop": '1%',
    "paddingBottom": '0%',
    "marginBottom": '0%'
};

const commentRow = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: "3%",
    paddingRight: "3%",
};

const commentCol = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingLeft: "3%",
    paddingRight: "3%",
};


const commentText = {
    "fontSize": "16px",
    "fontStyle": "normal",
    "fontWeight": "300",
};

// Specifies the TabPanel component that is being used
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    const box = {
        marginTop: "2%",
        padding: "1%"
    };

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3} style={box}>
                    <Typography >{children}</Typography>
                </Box>
            )}
        </div>
    );
}

// Props specification for TabPanel
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

// Creates an id and sets some style settings
// for a StyledTab.
// Allows the ability to scroll when there are too
// many comments to fit on the screen.
function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}